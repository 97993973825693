import React, { useEffect, useState } from "react";
import { CodeSnippet } from "../components/CodeSnippet";

const ToastfulSnippet = `import React from 'react';
import ReactDOM from 'react-dom';
import { Toastful } from 'react-toastful';

import './index.css';
import App from './App';

ReactDOM.render(
  <>
    <Toastful />
    <App />
  </>,
  document.getElementById('root')
);`;
const toastfulSnippet = `import { toastful } from "react-toastful";

const showToast = () => toastful('Hello world!', {
  visible: false,
});

export const YourComponent = () => {
  return (
    <button onClick={showToast}>
      Show Toast
    </button>  
  );
};`;
const CustomContainerSnippet = `import React from 'react';
import ReactDOM from 'react-dom';
import { Toastful } from 'react-toastful';

import './index.css';
import App from './App';

ReactDOM.render(
  <>
    <Toastful>
      {(output) => (
        <div className="...">{output}</div>
      )}
    </Toastful>
    <App />
  </>,
  document.getElementById('root')
);`;

const InlineCode = ({ children }) => {
  return (
    <span className="inline-block px-1 font-mono font-semibold text-blue-600 bg-blue-100 rounded my-0.5">
      {children}
    </span>
  );
};

const navigateToHash = (hash) => {
  const normalizedHash = hash.replace(/^#/, "") || "/";
  const el = document.getElementById(normalizedHash);
  el?.scrollTo({
    behavior: "smooth",
  });
};

export const Docs = () => {
  const [hash, setHash] = useState("");

  useEffect(() => {
    navigateToHash(hash);
  }, [hash]);

  useEffect(() => {
    const handler = () => setHash(window.location.hash);
    window.addEventListener("hashchange", handler, false);

    return () => {
      window.removeEventListener("hashchange", handler);
    };
  }, []);

  return (
    <div className="pt-6 space-y-10">
      <p className="text-gray-800">
        react-toastful has a small API but allows you to customize to your
        needs.
      </p>

      <section className="space-y-4">
        <h2 className="text-xl font-bold text-gray-800">Toastful</h2>
        <p className="text-sm text-gray-700">
          Add the Toastful component to your React application to get started.
          This component takes no configuration unless you want to specify
          custom rendering behaviour for your toasts.
        </p>
        <section className="space-y-4" id="basic-example">
          <a href="#basic-example">
            <h3 className="font-medium text-gray-800 text-md">Basic Example</h3>
          </a>
          <p className="text-sm text-gray-800">
            Below is a basic example which will utilise the default container
            style provided by <InlineCode>react-toastful</InlineCode>.
          </p>
          <CodeSnippet name="index.tsx" snippet={ToastfulSnippet} />
        </section>
        <section id="custom-container" className="space-y-4">
          <a href="#custom-container">
            <h3 className="font-medium text-gray-800 text-md">
              Custom Container Example
            </h3>
          </a>
          <p className="text-sm text-gray-700">
            You can provide a child function to the{" "}
            <InlineCode>Toastful</InlineCode> component to allow you to
            customize the look of the toast container. Every toast will assume
            this structure and style when defined.
          </p>
          <CodeSnippet name="index.tsx" snippet={CustomContainerSnippet} />
        </section>
      </section>

      <section className="space-y-4" id="toastful">
        <a href="#toastful">
          <h2 className="text-xl font-bold text-gray-800">toastful</h2>
        </a>
        <p className="text-sm text-gray-700">
          react-toastful provides a helper function to simplify the management
          of your toasts.
        </p>
        <section className="space-y-4" id="toastful-options">
          <a href="#toastful-options">
            <h3 className="font-medium text-gray-800 text-md">Options</h3>
          </a>
          <p className="text-sm text-gray-700">
            You can provide a number of different options to configure your
            toasts how you like.
          </p>
          <div className="overflow-x-scroll border border-gray-200 rounded-lg shadow-sm">
            <table className="w-full bg-white rounded-lg">
              <thead>
                <tr>
                  <th className="px-3 py-2 text-xs font-bold text-left text-gray-600 uppercase">
                    Property
                  </th>
                  <th className="px-3 py-2 text-xs font-bold text-left text-gray-600 uppercase">
                    Description
                  </th>
                  <th className="px-3 py-2 text-xs font-bold text-left text-gray-600 uppercase">
                    Possible Values
                  </th>
                  <th className="px-3 py-2 text-xs font-bold text-left text-gray-600 uppercase">
                    Default
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    position?: ToastPosition;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    Control the position of your toast within the viewport.
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>top</InlineCode>,
                    <InlineCode>top_left</InlineCode>,{" "}
                    <InlineCode>top_right</InlineCode>,{" "}
                    <InlineCode>bottom</InlineCode>,{" "}
                    <InlineCode>bottom_left</InlineCode>,{" "}
                    <InlineCode>bottom_right</InlineCode>.
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>top</InlineCode>
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    visible?: boolean;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    Default visibility of the toast. Set this value to false to
                    prevent toast from showing automatically.
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>true</InlineCode> or{" "}
                    <InlineCode>false</InlineCode>
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>true</InlineCode>
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    dismissOnClick?: boolean;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    Control whether a toast can be dismissed on click. Defaults
                    to false.
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>true</InlineCode> or{" "}
                    <InlineCode>false</InlineCode>
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>false</InlineCode>
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    duration?: number;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    How long the toast should show before dismissing itself. On
                    desktop, the toast timer will pause on hover and restart
                    when the mouse leaves the toast.
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    Any positive integer
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>Infinity</InlineCode>
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    kind?: ToastKind;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    The kind of toast to render when utilising default styling.
                    Renders with a colored icon and border depending on the kind
                    specified. <InlineCode>undefined</InlineCode> renders no
                    icon and border.
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>undefined</InlineCode>,{" "}
                    <InlineCode>success</InlineCode>,{" "}
                    <InlineCode>failure</InlineCode>,{" "}
                    <InlineCode>warning</InlineCode>
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>undefined</InlineCode>
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    visible?: boolean;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    Whether the toast is visible by default or not.
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>true</InlineCode>,{" "}
                    <InlineCode>false</InlineCode>,{" "}
                    <InlineCode>undefined</InlineCode>
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>undefined</InlineCode>
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    draggable?: boolean;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    Whether the toast can be dragged to dismiss.
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>true</InlineCode>,{" "}
                    <InlineCode>false</InlineCode>,{" "}
                    <InlineCode>undefined</InlineCode>
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    <InlineCode>false</InlineCode>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section className="space-y-4" id="toastful-api">
          <a href="#toastful-api">
            <h3 className="font-medium text-gray-800 text-md">API</h3>
          </a>
          <p className="text-sm text-gray-700">
            <InlineCode>toastful</InlineCode> returns a{" "}
            <InlineCode>ToastInstance</InlineCode> which has a few methods for
            managing your toast.
          </p>
          <div className="overflow-x-scroll border border-gray-200 rounded-lg shadow-sm">
            <table className="w-full bg-white rounded-lg">
              <thead>
                <tr>
                  <th className="px-3 py-2 text-xs font-bold text-left text-gray-600 uppercase">
                    Method
                  </th>
                  <th className="px-3 py-2 text-xs font-bold text-left text-gray-600 uppercase">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    dismiss: () {"=>"} void;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    Allows you to dismiss your toast programmatically.
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 font-mono text-gray-700 border-t">
                    toggle: () {"=>"} void;
                  </td>
                  <td className="px-3 py-2 text-gray-700 border-t">
                    Allows you to toggle your toast from visible to hidden and
                    vice versa.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section className="space-y-4" id="toastful-example">
          <a href="#toastful-example">
            <h3 className="font-medium text-gray-800 text-md">Example</h3>
          </a>
          <p className="text-sm text-gray-700">
            Below is an example of a toast which is hidden by default and shows
            on click of a button.
          </p>
          <CodeSnippet name="YourComponent.tsx" snippet={toastfulSnippet} />
          <p className="text-sm text-gray-700">
            The function itself returns the toast instance which allows you to
            call helper methods for managing your toasts.
          </p>
        </section>
      </section>
    </div>
  );
};
