import React from "react";

export const PositionSelector = ({ value, onChange }) => {
  return (
    <div className="grid w-32 grid-cols-3 grid-rows-2 mx-auto">
      <button
        aria-label="Move position to top left"
        onClick={() => onChange("top_left")}
        className={`outline-none focus:outline-none focus:bg-gray-50 w-full hover:bg-gray-50 h-12 rounded-tl ${
          value === "top_left"
            ? "border-t-2 border-l-2 border-blue-800 bg-gray-100"
            : "border-t border-l"
        }`}
      />
      <button
        aria-label="Move position to top"
        onClick={() => onChange("top")}
        className={`outline-none focus:outline-none focus:bg-gray-50 w-full hover:bg-gray-50 h-12 ${
          value === "top"
            ? "border-t-2 border-blue-800 bg-gray-100"
            : "border-t"
        }`}
      />
      <button
        aria-label="Move position to top right"
        onClick={() => onChange("top_right")}
        className={`outline-none focus:outline-none focus:bg-gray-50 w-full hover:bg-gray-50 h-12 rounded-tr ${
          value === "top_right"
            ? "border-t-2 border-r-2 border-blue-800 bg-gray-100"
            : "border-t border-r"
        }`}
      />
      <button
        aria-label="Move position to bottom left"
        onClick={() => onChange("bottom_left")}
        className={`outline-none focus:outline-none focus:bg-gray-50 w-full hover:bg-gray-50 h-12 rounded-bl ${
          value === "bottom_left"
            ? "border-b-2 border-l-2 border-blue-800 bg-gray-100"
            : "border-b border-l"
        }`}
      />
      <button
        aria-label="Move position to bottom"
        onClick={() => onChange("bottom")}
        className={`outline-none focus:outline-none focus:bg-gray-50 w-full hover:bg-gray-50 h-12 ${
          value === "bottom"
            ? "border-b-2 border-blue-800 bg-gray-100"
            : "border-b"
        }`}
      />
      <button
        aria-label="Move position to bottom right"
        onClick={() => onChange("bottom_right")}
        className={`outline-none focus:outline-none focus:bg-gray-50 w-full hover:bg-gray-50 h-12 rounded-br ${
          value === "bottom_right"
            ? "border-b-2 border-r-2 border-blue-800 bg-gray-100"
            : "border-b border-r"
        }`}
      />
    </div>
  );
};
