import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { githubGist } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toastful } from "react-toastful";

const CopiedMessage = () => (
  <div className="flex items-center space-x-2">
    <svg
      className="w-5 h-5 text-green-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <span>Copied</span>
  </div>
);

export const CodeSnippet = ({ name, snippet }) => {
  const copyCodeSnippet = () => {
    const el = document.createElement("textarea");
    el.value = snippet;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    toastful(<CopiedMessage />, { position: "bottom", duration: 5000 });
  };

  return (
    <div className="flex flex-col mt-6 bg-white border border-gray-200 rounded-lg shadow-sm">
      <div className="flex justify-end p-4 bg-white border-b rounded-t-lg">
        <button
          className="outline-none focus:outline-none"
          onClick={copyCodeSnippet}
        >
          <svg
            className="w-5 h-5 text-gray-400 hover:text-gray-800"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
            />
          </svg>
        </button>
      </div>
      <SyntaxHighlighter
        codeTagProps={{
          className: "text-sm",
        }}
        lineProps={{
          className: "text-gray-400",
        }}
        customStyle={{
          className: "rounded-lg",
        }}
        style={githubGist}
        language="tsx"
      >
        {snippet}
      </SyntaxHighlighter>
      <div className="p-4 bg-white border-t rounded-b-lg">
        <span className="text-sm font-semibold text-gray-400">{name}</span>
      </div>
    </div>
  );
};
