import { Listbox, Switch, Transition } from "@headlessui/react";
import { pathToRegexp } from "path-to-regexp";
import React, { useEffect, useState } from "react";
import { toastful, Toastful } from "react-toastful";
import {
  Link,
  Route,
  Router,
  Switch as WouterSwitch,
  useLocation,
} from "wouter";
import makeCachedMatcher from "wouter/matcher";
import { PositionSelector } from "./components/PositionSelector";
import { Docs } from "./pages/Docs";

const buttonClasses = [
  "px-3 py-2",
  "rounded-lg",
  "bg-blue-800 hover:bg-blue-700",
  "text-white",
  "font-medium",
  "outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700",
].join(" ");

let toast = toastful("Hello world", { visible: false });

const convertPathToRegexp = (path) => {
  let keys = [];
  const regexp = pathToRegexp(path, keys, { strict: true });
  return { keys, regexp };
};

const customMatcher = makeCachedMatcher(convertPathToRegexp);

const App = () => {
  const [location] = useLocation();
  const [toastConfig, setToastConfig] = useState({
    position: "top",
    default: true,
    visible: false,
    kind: undefined,
  });
  const [classNames, setClassNames] = useState("");

  useEffect(() => {
    toast.dismiss();
    toast = toastful("Hello world", {
      ...toastConfig,
      kind: toastConfig.default ? toastConfig.kind : undefined,
    });
  }, [toastConfig]);

  useEffect(() => {
    if (location !== "/") {
      toast.dismiss();
    }
  }, [location]);

  const handlePositionChange = (position) =>
    setToastConfig((c) => {
      return { ...c, position };
    });

  const handleDefaultChange = (e) =>
    setToastConfig((c) => {
      return { ...c, default: e };
    });

  return (
    <>
      {toastConfig.default ? (
        <Toastful />
      ) : (
        <Toastful>
          {(output) => (
            <div className={classNames}>
              <span>{output}</span>
            </div>
          )}
        </Toastful>
      )}
      <div className="w-full max-w-4xl px-4 pt-4 pb-16 mx-auto md:px-6 md:pt-24">
        <a
          className="absolute left-0 z-50 p-2 text-sm text-gray-800 bg-white rounded-lg -top-10 focus:top-0"
          href="#main"
        >
          Skip to main content
        </a>
        <header className="flex flex-col items-start justify-between space-y-4 sm:items-center sm:space-y-0 sm:flex-row">
          <Link
            href="/"
            className="flex items-center space-x-4 text-2xl font-extrabold tracking-tight text-gray-800 outline-none focus:outline-none"
          >
            <span className="text-4xl">🍜</span>
            <span>react-toastful</span>
          </Link>

          <ul className="flex items-center space-x-6">
            <li>
              <Link
                className="text-xs font-medium text-gray-500 uppercase transition-colors duration-150 ease-out border border-transparent rounded outline-none hover:text-gray-800 focus:outline-none focus:ring focus:ring-blue-200 focus:border-indigo-300 focus:ring-opacity-50"
                href="/docs"
              >
                Docs
              </Link>
            </li>

            <li>
              <Link
                className="text-xs font-medium text-gray-500 uppercase transition-colors duration-150 ease-out border border-transparent rounded outline-none hover:text-gray-800 focus:outline-none focus:ring focus:ring-blue-200 focus:border-indigo-300 focus:ring-opacity-50"
                href="/about"
              >
                About
              </Link>
            </li>
          </ul>
        </header>

        <main id="main">
          <Router matcher={customMatcher}>
            <WouterSwitch>
              <Route path="/">
                <div className="flex w-full space-x-4">
                  <div className="w-full">
                    <p className="mt-6 text-left text-gray-700">
                      <a
                        href="https://github.com/shannonrothe/react-toastful"
                        className="font-semibold text-blue-600 underline"
                      >
                        react-toastful
                      </a>{" "}
                      is a simple toast library for React. Opt for the default
                      style or customize to your needs 🔥
                    </p>
                    <div className="mt-6 text-left text-gray-700">
                      Reasons to love it:
                      <ul className="mt-3 list-inside">
                        <li className="pl-4 list-disc">
                          💅 <span className="font-medium">Beautiful</span> by
                          default,{" "}
                          <span className="font-medium">customizable</span> by
                          desire
                        </li>
                        <li className="pl-4 list-disc">
                          📉 <span className="font-medium">{"<"} 4.5kb</span>{" "}
                          bundle size (minified production build)
                        </li>
                        <li className="pl-4 list-disc">
                          ⚡️ <span className="font-medium">Simple</span> and{" "}
                          <span className="font-medium">useful</span> API
                        </li>
                      </ul>
                    </div>
                    <div className="w-full mt-10 bg-white border border-gray-200 rounded-lg shadow-sm">
                      <div className="p-4">
                        <label className="text-xs font-semibold text-gray-600 uppercase">
                          Position
                        </label>
                        <div className="py-6">
                          <PositionSelector
                            value={toastConfig.position}
                            onChange={handlePositionChange}
                          />
                        </div>
                      </div>

                      <div className="p-4 border-t">
                        <Switch.Group
                          as="div"
                          className="flex items-center space-x-2"
                        >
                          <Switch
                            as="button"
                            aria-label={
                              toastConfig.default
                                ? "Apply custom style with Tailwind class names"
                                : "Use default toast style"
                            }
                            checked={toastConfig.default}
                            onChange={handleDefaultChange}
                            className={`${
                              toastConfig.default
                                ? "bg-blue-800"
                                : "bg-gray-200"
                            } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 focus:shadow-outline`}
                          >
                            {({ checked }) => (
                              <span
                                className={`${
                                  checked ? "translate-x-5" : "translate-x-0"
                                } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                              />
                            )}
                          </Switch>
                          <Switch.Label className="text-sm text-gray-800">
                            Use default style
                          </Switch.Label>
                        </Switch.Group>
                        <Transition
                          show={toastConfig.default}
                          enter="transition-opacity duration-300 ease-out"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          className="mt-4"
                        >
                          <label className="text-xs font-semibold text-gray-600 uppercase">
                            Kind
                          </label>
                          <div className="mt-1">
                            <Listbox
                              value={toastConfig.kind ?? "none"}
                              onChange={(e) =>
                                setToastConfig((c) => ({
                                  ...c,
                                  kind: e === "none" ? undefined : e,
                                }))
                              }
                            >
                              <div className="relative">
                                <Listbox.Button className="flex items-center justify-between w-full px-3 py-2 space-x-2 text-left text-gray-800 bg-white border border-gray-200 rounded-lg shadow-sm outline-none focus:outline-none">
                                  <span className="text-sm">
                                    {toastConfig.kind
                                      ? toastConfig.kind[0].toUpperCase() +
                                        toastConfig.kind.substr(1)
                                      : "Default"}
                                  </span>
                                  <svg
                                    className="w-4 h-4 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M19 9l-7 7-7-7"
                                    />
                                  </svg>
                                </Listbox.Button>
                                <Listbox.Options className="absolute w-full py-1 mt-1 bg-white rounded-lg shadow outline-none focus:outline-none">
                                  <Listbox.Option
                                    className="outline-none focus:outline-none"
                                    value="none"
                                  >
                                    {({ active, selected }) => (
                                      <span
                                        className={`block px-3 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-50 focus:bg-gray-50 ${
                                          active || selected ? "bg-gray-50" : ""
                                        }`}
                                      >
                                        None
                                      </span>
                                    )}
                                  </Listbox.Option>

                                  <Listbox.Option
                                    className="outline-none focus:outline-none"
                                    value="success"
                                  >
                                    {({ active, selected }) => (
                                      <span
                                        className={`block px-3 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-50 focus:bg-gray-50 ${
                                          active || selected ? "bg-gray-50" : ""
                                        }`}
                                      >
                                        Success
                                      </span>
                                    )}
                                  </Listbox.Option>

                                  <Listbox.Option
                                    className="outline-none focus:outline-none"
                                    value="failure"
                                  >
                                    {({ active, selected }) => (
                                      <span
                                        className={`block px-3 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-50 focus:bg-gray-50 ${
                                          active || selected ? "bg-gray-50" : ""
                                        }`}
                                      >
                                        Failure
                                      </span>
                                    )}
                                  </Listbox.Option>

                                  <Listbox.Option
                                    className="outline-none focus:outline-none"
                                    value="warning"
                                  >
                                    {({ active, selected }) => (
                                      <span
                                        className={`block px-3 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-50 focus:bg-gray-50 ${
                                          active || selected ? "bg-gray-50" : ""
                                        }`}
                                      >
                                        Warning
                                      </span>
                                    )}
                                  </Listbox.Option>
                                </Listbox.Options>
                              </div>
                            </Listbox>
                          </div>
                        </Transition>
                        <Transition
                          show={!toastConfig.default}
                          enter="transition-opacity duration-300 ease-out"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                        >
                          <div className="mt-4">
                            <label className="text-xs font-semibold text-gray-600 uppercase">
                              Tailwind Classes
                            </label>
                            <input
                              onChange={(e) =>
                                setClassNames(e.target.value.toLowerCase())
                              }
                              value={classNames}
                              spellCheck={false}
                              placeholder="bg-pink shadow-sm rounded-lg text-white font-medium px-3 py-2"
                              className="block w-full px-3 py-2 mt-1 text-sm text-gray-800 border rounded-lg shadow-sm outline-none focus:outline-none"
                            />
                          </div>
                        </Transition>
                      </div>

                      <div className="p-4 bg-gray-100 border-t rounded-b-lg">
                        <button
                          className={`${buttonClasses} w-full`}
                          onClick={() => {
                            setToastConfig((c) => ({
                              ...c,
                              visible: !c.visible,
                            }));
                          }}
                        >
                          {toastConfig.visible ? "Hide toast" : "Show Toast"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Route>
              <Route path="/docs" component={Docs} />
              <Route path="/about">
                <div className="pt-6">
                  <p className="text-gray-700">
                    <a
                      href="https://github.com/shannonrothe/react-toastful"
                      className="font-semibold text-blue-600 underline"
                    >
                      react-toastful
                    </a>{" "}
                    is inspired by the amazing{" "}
                    <a
                      href="https://react-hot-toast.com"
                      className="font-semibold text-blue-600 underline"
                    >
                      react-hot-toast
                    </a>{" "}
                    library by{" "}
                    <a
                      className="font-semibold text-blue-600 underline"
                      href="https://twitter.com/timolins"
                    >
                      Timo Lins
                    </a>
                    . I have found that existing solutions for toasts would
                    always just miss the mark. Whether they're unconfigurable,
                    lack customization, or offer a poor API – react-hot-toast
                    offered solutions for all three.
                  </p>

                  <p className="mt-4 text-gray-700">
                    With that in mind, thanks for the inspiration and I hope
                    people find use and have as much fun with this library as I
                    had building it 💪
                  </p>
                </div>
              </Route>
            </WouterSwitch>
          </Router>
        </main>
        <footer className="mt-10 text-sm text-center text-gray-600">
          Built with <span className="text-red-600">&hearts;</span> by{" "}
          <a
            href="https://twitter.com/shannonrothe"
            className="font-semibold border border-transparent rounded outline-none focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:border-blue-300"
          >
            @shannonrothe
          </a>
        </footer>
      </div>
    </>
  );
};

export default App;
